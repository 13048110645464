import { Home } from 'layouts';
import { HOME_POSTS } from 'lib/graphql/queries/home';
import { GetStaticProps } from 'next';
import { createApolloClient } from 'lib/graphql/apollo';
import { HomePosts } from 'types/home';

export const getStaticProps: GetStaticProps = async () => {
    const client = createApolloClient();

    const { data } = await client.query<HomePosts>({
        query: HOME_POSTS,
        variables: {
            limit: 9,
            skip: 0,
        },
    });

    return {
        props: {
            posts: data,
        },
        revalidate: 600, // 10 minutes
    };
};

export default Home;
